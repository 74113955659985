import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { Container, Box, Stack, useBreakpointValue } from '@chakra-ui/react'
import { H3Title } from 'src/uikit'
import Carousel from '../common/Carousel'

const Article = dynamic(() => import('./Article'))

interface Props {
  title: string
  articles: PressGlobal.PressItem[]
}

const PressList: FC<Props> = ({ title, articles }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return <Box bgColor="baseNeutral.100" pt="xxl6">
    <Container as={Stack} maxW="6xl" pb="xxl6" px="l">
      <H3Title>{title}</H3Title>
      <Carousel
        breakpoints={{
          320: {
            slidesPerView: 1.3,
            spaceBetween: 8,
            centeredSlides: true
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }}
        withPagination
        className={isMobile ? 'has-partial-slide' : null}
      >
        {articles.map(article => {
          return (
            <Carousel.Item key={article.excerpt}>
              <Article
                excerpt={article.excerpt}
                image={article?.image}
                target={article.target}
              />
            </Carousel.Item>
          )
        })}
      </Carousel>
    </Container>
  </Box>
}

export default PressList
